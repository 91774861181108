import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { SectionTitle } from "../../components/Section-Title"
import PageHeader from "../../components/page-header"
import { Copy } from "../../styles/typography"
import MainButton from "../../components/main-button"
import { Colors } from "../../colors/Colors"
import { graphql, Link } from "gatsby"
import FaqItem from "../../components/faq-item"
import Fade from "react-reveal/Fade"
import { AppointmentButton } from "../../components/appointment/appointment-button"
import InvisalignAwards from "../../components/invisalign-awards"

const MainContainer = styled.div`
  display: flex;
  padding: 50px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`
const PlatHightlight = styled.span`
  text-decoration: underline;
  color: #b10f15;
`
const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
  }
`
const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 4%;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`
const Title = styled.h1`
  color: ${Colors.gold};
  font-family: "Articulat";
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 20px;
`
const ItemCopy = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #5d5d5d;
`
const Detail = styled.h5`
  font-size: 15px;
  font-family: "Articulat";
  text-transform: uppercase;
  font-size: 16px;
  color: #676767;
  margin-bottom: 15px;
`
const FaqContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  padding: 10px;
`
const FaqGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 30px;
  margin: 40px auto;

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
`
const ServicesContainer = styled.div`
  margin: 30px 0 0;
`
const Certified = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  @media (max-width: 708px) {
    margin-bottom: 0px;
  }
`
const InvisalignImage = styled.img`
  height: 100px;
  width: auto;
  margin: 0;
  object-fit: contain;
`

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  display: grid;
  margin-bottom: 50px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 10px;
  flex: 1;
`
const Avatar = styled.img`
  width: 275px;
  object-fit: cover;
  height: 310px;
`
class InvisalignPage extends Component {
  constructor(props) {
    super(props)
    this.faqRef = React.createRef()

    this.state = {}
  }

  handleLearnMore = () => {
    this.faqRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Invisalign"
          description="Many people want straighter teeth, but they don’t want the ugly metal brackets and the discomfort that comes with traditional orthodontic work. If you fit this description, you may want to consider Invisalign."
        />
        <PageHeader image={require("../../images/invisalign.jpg")} />
        <MainContainer>
          <div style={{ marginBottom: 60 }}>
            <InvisalignAwards />
          </div>
          {/* <GalleryContainer>
            <Fade bottom distance="20px">
              {gallery.map((item, index) => (
                <Container key={index}>
                  <Avatar src={item.avatar} />
                </Container>
              ))}
            </Fade>
          </GalleryContainer> */}
          <Copy style={{ marginBottom: 80, fontSize: "1rem" }}>
            Here's how you can get started:
          </Copy>
          <Grid>
            <Fade bottom distance={"10px"} cascade>
              <Item>
                <Detail>Step 1:</Detail>
                <Title>Begin with a complimentary virtual consultation</Title>
                <ItemCopy>
                  One of our dentists will examine your teeth and show you what
                  Invisalign treatment can do for you and your smile.
                </ItemCopy>

                <AppointmentButton />
              </Item>
            </Fade>
            <Fade bottom distance={"10px"} cascade>
              <Item>
                <Detail>Step 2:</Detail>
                <Title>Get a personalized treatment plan</Title>
                <ItemCopy>
                  Using the iTero scanner, you will get a fast, precise digital
                  scan (no goopy putty required). Our dentists will map out a
                  custom treatment plan just for you. You'll even get to preview
                  your new smile!
                </ItemCopy>
                <MainButton onClick={() => this.handleLearnMore()}>
                  Learn More
                </MainButton>
              </Item>
            </Fade>
            <Fade bottom distance={"10px"} cascade>
              <Item>
                <Detail>Step 3:</Detail>
                <Title>Receive your custom Invisalign aligners</Title>
                <ItemCopy>
                  Your aligners are custom made for you in the largest, most
                  advanced mass customization process in the world.
                </ItemCopy>
                <MainButton onClick={() => this.handleLearnMore()}>
                  Learn More
                </MainButton>
              </Item>
            </Fade>
          </Grid>
          <hr ref={this.faqRef} style={{ width: "100%", opacity: 0.3 }} />
          <FaqContainer>
            <SectionTitle>Frequently Asked Questions</SectionTitle>
            <FaqGrid>
              {faqs.map((faq, index) => (
                <FaqItem title={faq.title} copy={faq.copy} />
              ))}
            </FaqGrid>
          </FaqContainer>
        </MainContainer>
      </Layout>
    )
  }
}

export default InvisalignPage
const faqs = [
  {
    title: "What is Invisalign?",
    copy: [
      "Invisalign is the virtually invisible way to straighten your teeth and achieve the smile you’ve always dreamed of. Using advanced 3D computer-imaging technology, Invisalign depicts your complete treatment plan based on your Invisalign Provider’s prescription, from the initial position of your teeth to the final desired position. Then a series of clear aligners are custom-made for your teeth – and your teeth only – to move them little by little. Each aligner is worn for 1-2 weeks before being replaced by the next in the series, gradually moving towards the projected final position. Your Invisalign treatment time will be determined by your Invisalign Provider, based on your specific needs.",
    ],
  },
  {
    title: "What are the benefits of Invisalign?",
    copy: [
      "Invisalign aligners are nearly invisible. No one may even notice that you’re wearing these virtually invisible aligners, making Invisalign a seamless fit with your lifestyle and day-to-day interactions with others.",
      "Invisalign aligners are removable giving you the flexibility to eat and drink what you want during treatment by simply removing the aligners. And you can also remove the aligners to brush and floss as you normally would for fresh breath and to maintain good oral hygiene.",
      "Unlike traditional fixed braces, there are no metal brackets or wires. This means that in most cases less time is spent in your Invisalign Provider’s practice for adjustments.",
      "And finally, Invisalign allows you to view your virtual results and treatment plan before you even begin treatment so you can see how your teeth are expected to look when your treatment is complete.",
    ],
  },
  {
    title: "How much does Invisalign cost?",
    copy: [
      "As with other types of orthodontic treatment, the cost of Invisalign is dependent on the complexity of your issue and your course of treatment. As a general rule the cost is comparable to traditional fixed braces.",
    ],
  },

  {
    title: "How does Invisalign work?",
    copy: [
      "Invisalign aligners move teeth with an appropriate placement of controlled movement on the teeth. Unlike traditional braces, Invisalign not only controls the amount of movement per aligner, but also the timing of the movement. So at each stage, only certain teeth are allowed to move, as per the Invisalign treatment plan for that particular stage. This results in an efficient force delivery system that moves teeth into the desired prescribed position.",
    ],
  },
  {
    title: "Is the Invisalign treatment painful?",
    copy: [
      "Some people may experience temporary, minor discomfort for a few days, at the beginning of each new stage of treatment when they insert a new aligner. This is normal and is typically described as a feeling of pressure. It is a sign that the aligners are working – moving your teeth gradually, helping you to achieve the final results. This discomfort typically goes away in a couple of days.",
    ],
  },
  {
    title: "How long does the Invisalign treatment take?",
    copy: [
      "The total duration of treatment depends on the complexity of the treatment and is generally comparable to that of traditional braces. Typically treatment can last between 3 and 18 months. During your consultation, your Invisalign Provider can provide you with more detailed information.",
    ],
  },
]

export const generalQuery = graphql`
  query($title: String = "Invisalign Teeth Straightening") {
    allWordpressPage(filter: { title: { eq: $title } }) {
      edges {
        node {
          featured_media {
            source_url
            localFile {
              childImageSharp {
                fluid(maxHeight: 1500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
