import styled from 'styled-components'

export const PageTitle = styled.h1`
font-size: 48px;
color: #121212;
letter-spacing: -1.16px;
/* line-height: 69px; */

@media (max-width: 600px) {
    
font-size: 36px;
}
`

export const Copy = styled.p`
font-size: 1rem;
color: #404040;
letter-spacing: -0.68px;

`