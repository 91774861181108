import React, { Component } from "react"
import styled from "styled-components"
import { Colors } from "../colors/Colors"

const Container = styled.div``
const Title = styled.h1`
  color: ${Colors.gold};
  font-family: "Articulat";
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 20px;

  @media (max-width: 700px) {
    margin-top: 30px;
  }
`
const Copy = styled.p`
  max-width: 600px;
  margin-bottom: 15px;
  font-size: 16px;
`

class FaqItem extends Component {
  state = {}
  render() {
    return (
      <Container>
        <Title>{this.props.title}</Title>
        {this.props.copy.map((cop, item) => (
          <Copy>{cop}</Copy>
        ))}
      </Container>
    )
  }
}

export default FaqItem
